import React from 'react'
import GatsbyLink  from './link'

class Breadcrumbs extends React.Component {
  

  render() { 
   const breadcrumb = this.props.breadcrumb || [];
   const breadcrumbLen = breadcrumb.length;
   //console.log("breadcrumb",breadcrumb);
    return (
      <div id="breadcrumbs" className="breadcrumbs clearing" role="navigation" aria-label="breadcrumb">
        <div className="row">
          <div className="col-12">
            <ol className="breadcrumbs__list clearfix" itemType="http://schema.org/BreadcrumbList" itemScope="itemScope">
              <li className="breadcrumbs__item" itemType="http://schema.org/ListItem" itemScope="itemScope" itemProp="itemListElement">
                <span aria-level="1" itemProp="item">
                  <GatsbyLink to="/" title="Home" className="breadcrumbs__link" itemProp="url"><span itemProp="name">Home</span></GatsbyLink> /
                </span>
                <meta content="1" itemProp="position" />
              </li>
              {breadcrumb.map((item,index) => {
                let page = <span itemProp="name">{item.title}</span>;
                let separator = "";
                
                if (index<breadcrumbLen - 1 ) {
                   page = <GatsbyLink to={item.link} title="Servizi" className="breadcrumbs__link" itemProp="url"><span itemProp="name">{item.title}</span></GatsbyLink>;
                   separator = " / ";
                }

                return <li key={index} className="breadcrumbs__item" itemType="http://schema.org/ListItem" itemScope="itemScope" itemProp="itemListElement">
                <span aria-level="2" itemProp="item">&#160;{page}</span>
                {separator}
                <meta content={index+2} itemProp="position" />
              </li>
              })}
            </ol>
          </div>
        </div>
      </div>
    )
  }

}

export default Breadcrumbs