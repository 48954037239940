import React from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

class PlaceHolder extends React.Component {

  render() {
    const data = this.props.data; 
    const variation = !!this.props.variation?"placeholder--"+this.props.variation:"";
    return (
      <div className={"placeholder "+variation}>
        <Link to={data.friendlyUrl} title={data.titolo} className="placeholder__link">
          {!!data.immagine &&
            <Img fluid={data.immagine.fluid} className="placeholder__img" />
          }
          <span className="placeholder__title">{data.titolo}</span>
          
          {!!data.subtitle &&
            <span className="placeholder__subject">{data.subtitle}</span>
          }
        </Link>
      </div>
    )
  }

}

export default PlaceHolder