import React from 'react'
import Helmet from 'react-helmet'
import PlaceHolder from '../components/placeholder'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import Breadcrumbs from '../components/breadcrumbs'


class Reparti extends React.Component {

  render() { 
    const home = this.props.data.contentfulLayoutHome;
    const reparti = home.reparti;
    const breadcrumb = [{"title":"Reparti"}];

    return (
    <Layout location={this.props.location}>
      <div className="container">
        
        <Helmet title="Reparti"/>
        <Breadcrumbs breadcrumb={breadcrumb}/>

      
        <div className="row">
          <div className="col-12">
          
            <section className="section"  aria-labelledby="reparti">
              <h1 id="reparti" className="section__title">Reparti</h1>
              <div className="row flex-wrap">
                {reparti.map((item,index) => 
                  <div key={index} className="col-6">
                      <PlaceHolder data={item}/>
                  </div>
                )}
              </div>
            </section>
      
          </div>
        </div>
      </div>

    </Layout>
    )
  }
}
export default Reparti;
export const query = graphql`
query RepartiQuery{
  contentfulLayoutHome (contentful_id: {eq: "4Db1pTHULOAd0bUx7Hjwi8"}) {
    reparti {
      immagine {
        fluid(maxWidth: 570){
          ...GatsbyContentfulFluid_withWebp
        }
      }
      titolo
      friendlyUrl
      descrizione {
        childMarkdownRemark {
          html
        }
      }
    }
  }
 
}
`
